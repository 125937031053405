@import "utils/all";

$body-background-color: $blue-grey-100;
$body-size: 16px;
$body-rendering: $render-mode;

html {
  background-color: $body-background-color;
  font-size: $body-size;
  overflow-x: hidden;

  @include sm-mobile {
    background-color: $white;
  }
}

body {
  font-family: $family-sans-serif;
  font-size: 1rem;
}

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $link-hover;
  }
}

.overlay-spinner-container {
  @include modal-overlay;

  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding-top: 25vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-fab;

  .layout {
    text-align: center;
  }

  .spinner {
    width: 40px;
    height: 40px;
    display: inline-block;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: $color-brand;
  }
}
