// Animations with Keyframes
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
