/// Convert a given px unit to a rem unit
/// @param {Number} $px - Number with px unit
/// @return {Number} Number with rem unit
@use "sass:math";

@function px--rem($px) {
  @return math.div($px, 16px) * 1rem;
}

/// 8pt Baseline Grid value
/// Get the value of a specific step in a 8pt grid scale
/// @param {Number} $step - Degree of 8pt increments
/// @return {Number} Value in pixels, converted from rem
@function baseline-scale($step) {
  @return (px--rem($step * 8px));
}

/// 4pt Grid
/// Get the value of a specific step in a 4pt grid scale
/// @param {Number} $step - Degree of 8pt increments
/// @return {Number} Value in pixels, converted from rem
@function grid-4pt($step) {
  @return (px--rem($step * 4px));
}

/// Slightly lighten a color
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

/*
  MOBILE ONLY FUNCTIONS
*/

/// Convert Pixels to DIP (Device Independent Pixels) for NativeScript
/// @param {Number} $px - Number with px unit
/// @return {Number} Number with DIP (no listed) unit
@function px--dip($px) {
  @return math.div($px, $px * 0 + 1);
}

/// Convert REM to DIP (Device Independent Pixels) for NativeScript
/// @param {Number} $rem - Number with rem unit
/// @return {Number} Number with DIP (no listed) unit
@function rem--dip($rem) {
  @return (math.div($rem, $rem * 0 + 1) * 16);
}
