@import 'initial-variables';
@import 'functions';
@import 'derived-variables';
/// Creates standard styling box ui
///
/// @param {number} $elevation
///   The degree of distance the box should elevate off the page / "surface"
@mixin box($elevation) {
  background: $white;
  border-radius: $radius-m;
  padding: $space-m;

  @if ($elevation == 1) {
    box-shadow: $box-shadow-01;
  }

  @else if ($elevation == 2) {
    box-shadow: $box-shadow-02;
  }

  @else if ($elevation == 3) {
    box-shadow: $box-shadow-03;
  }

  @else if ($elevation == none) {
    border: solid px--rem(1px) $border-color;
  }

}

/// Media Query Mixns
///
// Creates standardized media queries
@mixin maxScreenWidth($screenSize) {
  @media screen and (max-width: $screenSize - px--rem(1px)) {
    @content;
  }
}
@mixin minScreenWidth($screenSize) {
  @media screen and (min-width: $screenSize) {
    @content;
  }
}
@mixin minToMaxScreenWidth($min, $max) {
  @media screen and (min-width: $min) and (max-width: $max - px--rem(1px)) {
    @content;
  }
}

@mixin maxScreenHeight($max) {
  @media screen and (max-height: $max - px--rem(1px)) {
    @content;
  }
}

@mixin sm-mobile {
  @include maxScreenWidth($mobile) { @content; }
}

@mixin lg-mobile {
  @include minToMaxScreenWidth($mobile, $tablet) { @content; }
}

@mixin until-tablet {
  @include maxScreenWidth($tablet) { @content; }
}

@mixin tablet {
  @include minScreenWidth($tablet) { @content; }
}

@mixin tablet-height {
  @include maxScreenHeight($tablet) { @content; }
}

@mixin tablet-only {
  @include minToMaxScreenWidth($tablet, $desktop) { @content; }
}

@mixin until-desktop {
  @include maxScreenWidth($desktop) { @content; }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - px--rem(1px)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - px--rem(1px)) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - px--rem(1px)) {
    @content;
  }
}

@mixin until-fullhd {
  @media screen and (max-width: $fullhd - px--rem(1px)) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}

@mixin fullhd-only {
  @media screen and (min-width: $fullhd) and (max-width: $megahd - px--rem(1px)) {
    @content;
  }
}

@mixin megahd-only {
  @media screen and (min-width: $megahd) and (max-width: $morehd - px--rem(1px)) {
    @content;
  }
}

@mixin megahd {
  @media screen and (min-width: $megahd) {
    @content;
  }
}

@mixin until-megahd {
  @media screen and (max-width: $megahd - px--rem(1px)) {
    @content;
  }
}

@mixin only-morehd {
  @include minToMaxScreenWidth($morehd, $ultrahd) { @content; }
}

@mixin morehd {
  @media screen and (min-width: $morehd) {
    @content;
  }
}

@mixin until-morehd {
  @include maxScreenWidth($morehd) { @content; }
}

@mixin only-ultrahd {
  @include minToMaxScreenWidth($ultrahd, $uberhd) { @content; }
}

@mixin ultrahd {
  @media screen and (min-width: $ultrahd) {
    @content;
  }
}

@mixin until-ultrahd {
  @include maxScreenWidth($ultrahd) { @content; }
}

@mixin uberhd {
  @media screen and (min-width: $uberhd) {
    @content;
  }
}

@mixin until-uberhd {
  @include maxScreenWidth($uberhd) { @content; }
}

/// Typography Mixins
///
// Creates standardized typography mixins

// Display Text
$display-text-font-size: px--rem(60px);
$display-text-line-height: baseline-scale(9); // 72px

@mixin display-text {
  font-size: $display-text-font-size;
  line-height: $display-text-line-height;
  font-weight: $weight-normal;
  color: currentColor;
}

// Title
$title-font-weight: $weight-normal;

@mixin title-02 {
  font-size: px--rem(26px);
}

@mixin title-01 {
  font-size: px--rem(22px);
  line-height: baseline-scale(4); // 32px
}

@mixin input-base {
  height: $gap-6;
  line-height: $line-height-2;
  font-size: $base-font-size;
  color: $primary-dark-100;
  font-weight: $weight-medium;
  border: none;
}

@mixin input-01 {
  @include placeholder() {
    color: $blue-grey-400;
  }

  @include input-base;
}

// Heading
$heading-font-size: px--rem(18px);
$heading-font-weight: $weight-normal;
$heading-line-height: baseline-scale(3);  // 24px

@mixin heading {
  font-size: $heading-font-size;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
}

// Subheading
$subheading-font-size: px--rem(16px);
$subheading-font-weight: $weight-medium;
$subheading-line-height: baseline-scale(2);  // 16px

@mixin subheading {
  font-size: $subheading-font-size;
  font-weight: $subheading-font-weight;
  line-height: $subheading-line-height;
}

// Status Text
$status-text-font-size: px--rem(17px);
$status-text-font-weight: $weight-normal;
$status-text-line-height: baseline-scale(3); // 24px
$status-text-transform: uppercase;

@mixin status-text {
  font-size: $status-text-font-size;
  font-weight: $status-text-font-weight;
  line-height: $status-text-line-height;
  text-transform: $status-text-transform;
}

// Body
@mixin body-01 {
  font-size: px--rem(16px);
  max-width: 65ch;
}

@mixin body-02 {
  font-size: px--rem(17px);
  max-width: 65ch;
}

@mixin body-03 {
  font-size: px--rem(18px);
  max-width: 65ch;
}

// Caption
@mixin caption-03 {
  font-size: px--rem(16px);
}

@mixin caption-02 {
  font-size: px--rem(14px);
}

@mixin caption-01 {
  font-size: px--rem(12px);
  line-height: baseline-scale(2);
}

// Modal Mixins
// Common Spense Modal Layouts

@mixin modal-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: $space-m;
  padding-top: $space-m;

  @include desktop-only {
    grid-template-columns: 1fr;
    border-top: px--rem(3px) solid $color-brand;
  }

  @include fullhd {
    grid-template-columns: 1fr 1fr;
    border-top: px--rem(3px) none $color-brand;
  }

  &[alert-present="true"] {
    border-top: unset;
    padding-block-end: 1rem;
  }
}

@mixin modal-overlay {
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 65px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity $speed;

  @supports not (backdrop-filter: blur(8px)) {
    background-color: rgba(255, 255, 255, 0.5);
  }

  @include until-widescreen {
    top: 0;
  }
}
// Global Form Mixins
// Placeholder - Automates generation of placeholder text styling
@mixin placeholder {
  $placeholders: ":-moz" ":-webkit-input" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

// Account for sidenav
@mixin sidenav-space {
  left: 16.25rem;

  @include until-desktop {
    left: 0;
  }

  @include desktop-only {
    left: 5.25rem;
  }
}
