/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// Import Utilities
@import './assets/scss/utils/all';

// Import Base
@import './assets/scss/base/all';

// Import Forms
@import './assets/scss/forms/forms';

// Import webfonts
@import 'assets/fonts/rubik.scss';
@import 'assets/fonts/icomoon.scss';

// Import angular material styles
@import './assets/scss/components/material-theme';

// NB Choices Styling
// @import './assets/scss/nb-choices/nb-choices';

// Import toast styling
@import '../../../node_modules/ngx-toastr/toastr.css';
@import '../../../node_modules/ngx-snackbar/bundles/style.css';
@import "~@ng-select/ng-select/themes/material.theme.css";

.mat-dialog-container:has(sis-unsaved-changes-alert),
.mat-dialog-container:has(sis-discard-changes-alert) {
  background-color: transparent;
  padding: 0;
  border-radius: 1rem;
}

ngx-snackbar .snackbars {
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  width: 90vw;

  @media (max-width: 600px) {
    left: 50% !important;
    transform: translate(-50%);

    .snack {
      min-width: unset;
    }
  }
}
