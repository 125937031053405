/// Form Control Mixins
///
// Creates standardized mixins for form elements

// Control - Sets foundation for all control elements
$control-height: grid-4pt(15);
$control-line-height: 1.5;
$control-padding-horizontal: $space-m + $space-xs;
$control-padding-vertical: $space-m + $space-xs;
$control-radius: $radius-s;
$control-border-width: px--rem(1px);
$control-font-size: 1rem;

@mixin control {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: $control-height;
  border: $control-border-width solid transparent;
  border-radius: $control-radius;
  padding: 0 $control-padding-horizontal;
  position: relative;
  font-size: $control-font-size;
  line-height: $control-line-height;

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

// Placeholder - Automates generation of placeholder text styling
@mixin placeholder {
  $placeholders: ":-moz" ":-webkit-input" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

// Field - Creates a wrapper container for form controls
@mixin field {
  margin-bottom: $space-m;
}

@mixin field-horizontal {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-m;

  .ems-label {
    flex: 0 0 100%;
  }

  .ems-control {
    flex: 0 0 calc(50% - 20px);
  }

  ems-datepicker {
    width: calc(50% - 20px);
    min-width: px--rem(180px);
  }
}

.ems-field {
  @include field;
}

.ems-field-horizontal {
  @include field-horizontal;
}

// Label - Labels for form controls
@mixin label {
  @include caption-02;

  color: $text-subtle;
  display: block;
  font-weight: $weight-medium;
  line-height: baseline-scale(3);

  @include until-desktop {
    font-weight: $weight-normal;
    color: $text-muted;
  }
}

.ems-label {
  @include label;

  height: baseline-scale(3);
  display: flex;

  &__optional {
    margin-left: auto;
    color: $text-muted;
    font-weight: $weight-normal;
  }

  &__note-text {
    font-weight: $weight-light;
    color: $text-subtle;
  }

  &--expense-note {
    height: grid-4pt(10);
    align-items: center;
  }
}

// Input - Sets styling and shareable state to input contols
$input-background: $white;
$input-color: $text;
$input-border-color: $blue-grey-200;

$input-focus-box-shadow-size: 0 0 2px 0.125em;
$input-focus-border-color: $link;
$input-focus-box-shadow-color: $link;

$input-disabled-background-color: $blue-grey-150;
$input-disabled-border-color: $blue-grey-150;
$input-disabled-color: $blue-grey-600;
$input-disabled-placeholder-color: $blue-grey-300;

$input-placeholder-color: $blue-grey-400;
$input-placeholder-line-height: 3.5;

@mixin input {
  @include control;

  background: $input-background;
  color: $input-color;
  border-color: $input-border-color;
  transition: all $speed-fastest ease-in-out;
  width: 100%;

  @include placeholder {
    color: $input-placeholder-color;
    font-weight: $weight-light;
    line-height: $input-placeholder-line-height;
  }

  &.input-error {
    border-color: $color-danger;
  }

  &[disabled],
  fieldset[disabled] & {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    box-shadow: none;
    color: $input-disabled-color;

    @include placeholder {
      color: $input-disabled-placeholder-color;
    }
  }
}

// Standard Inputs
.ems-input {
  @include input;
}

// Select Input
.ems-select {
  display: inline-block;
  width: 100%;
  vertical-align: top;

  select {
    @include input;

    background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill:hsl(212, 14%, 50%);fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath id='Path_1521' d='M0,0.749c0,-0.411 0.339,-0.749 0.75,-0.749c0.199,0 0.389,0.079 0.53,0.219l4,3.993l3.991,-3.993c0.14,-0.135 0.327,-0.21 0.521,-0.21c0.411,0 0.75,0.339 0.75,0.75c0,0.195 -0.076,0.382 -0.211,0.521l-4.521,4.523c-0.141,0.141 -0.332,0.22 -0.531,0.22c-0.198,0 -0.389,-0.079 -0.53,-0.22l-4.525,-4.523c-0.143,-0.139 -0.223,-0.331 -0.224,-0.531Z' style='fill-rule:nonzero;'/%3E%3C/svg%3E");
    background-position: center right 20px;
    background-repeat: no-repeat;
    background-size: px--rem(14px) auto;
    cursor: pointer;
    display: block;
    font-size: 1em;
    outline: none;
    padding-right: $control-height + $control-padding-horizontal;
    right: 0;
    top: 0;
    width: 100%;
  }

  &.is-invalid {
    select {
      border-width: 1px !important;
      border-style: solid !important;
      border-radius: 3px !important;
      border-color: $faded-red !important;
    }
  }
}

// Number Inputs - hide default arrow increments
.ems-control {
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

// Textareas
.ems-textarea {
  @include input;

  padding-top: $control-padding-vertical;
  padding-bottom: $control-padding-vertical;
  max-width: 100%;
  width: 100%;
  resize: vertical;

  &:not([rows]) {
    max-height: 30rem;
    min-height: 8rem;
  }

  &[rows] {
    height: initial;
  }

  @include placeholder {
    line-height: normal;
  }
}

sis-form-control {
  .form-control {
    &-select {
      line-height: 1.5;
      background-image: url("data:image/svg+xml,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill:hsl(212, 14%, 50%);fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;'%3E%3Cpath id='Path_1521' d='M0,0.749c0,-0.411 0.339,-0.749 0.75,-0.749c0.199,0 0.389,0.079 0.53,0.219l4,3.993l3.991,-3.993c0.14,-0.135 0.327,-0.21 0.521,-0.21c0.411,0 0.75,0.339 0.75,0.75c0,0.195 -0.076,0.382 -0.211,0.521l-4.521,4.523c-0.141,0.141 -0.332,0.22 -0.531,0.22c-0.198,0 -0.389,-0.079 -0.53,-0.22l-4.525,-4.523c-0.143,-0.139 -0.223,-0.331 -0.224,-0.531Z' style='fill-rule:nonzero;'/%3E%3C/svg%3E");
      background-position: center right 20px;
      background-repeat: no-repeat;
      background-size: px--rem(14px) auto;
      cursor: pointer;
      width: 100%;
    }

    &-textarea {
      line-height: 1.5;
      resize: vertical;
      max-height: 30rem;
      min-height: 8rem;
    }
  }

  *:disabled {
    cursor: not-allowed;
  }
}

// Radios
.ems-radio {
  @include caption-02;

  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-size: px--rem(13px);
  height: 1.75rem;
  width: 4.15rem;
  color: $text-subtle;
  transition: all $speed-faster $ease-in-out-quad;
  user-select: none;

  // Visually hide browser default radio
  input {
    cursor: pointer;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // This is the styled radio indicator
  &__indicator {
    height: px--rem(18px);
    width: px--rem(18px);
    border-radius: 50%;
    background: transparent;
    border: px--rem(2px) solid $blue-grey-300;
    margin-right: $space-xs;
    transition: all $speed-faster $ease-in-out-quad;
    position: relative;

    // This is the inner circle on the indicator
    &::after {
      position: absolute;
      opacity: 0;
      content: '';
      height: px--rem(10px);
      width: px--rem(10px);
      background: $blue-500;
      border-radius: 50%;
      top: px--rem(2px);
      left: px--rem(2px);
      transition: all $speed-faster $ease-in-out-quad;
    }
  }

  &__option {
    @include until-desktop {
      position: absolute;
      color: $blue-grey-400;
      font-size: px--rem(14px);
      left: 46%;
    }
  }

  // Hover State (unchecked)
  &:hover {
    color: $text;
  }

  input:checked ~ .ems-radio__indicator {
    border-color: $blue-500;
  }

  input:checked ~ .ems-radio__indicator::after {
    opacity: 1;
  }

  // Disabled
  input:disabled {
    
    & ~.ems-radio__indicator {
     border-color: $input-disabled-background-color;
     background-color: $input-disabled-background-color;
    }

    &:checked ~.ems-radio__indicator {
      border-color: $input-disabled-background-color;
      background-color: transparent;

      &::after {
        background: $input-disabled-background-color;
      }
    }
    
  }
}

// Checkboxes
.ems-checkbox {
  @include caption-02;

  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  font-size: px--rem(13px);
  font-size: px--rem(14px);
  height: baseline-scale(3);
  color: #1D3148;
  transition: all $speed-faster $ease-in-out-quad;
  user-select: none;

  // Visually hide browser default checkbox
  input {
    cursor: pointer;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // This is the styled checkbox indicator
  &__indicator {
    height: px--rem(22px);
    width: px--rem(22px);
    background: transparent;
    border: px--rem(2px) solid $input-border-color;
    border-radius: $radius-xs;
    margin-right: ($space-xs + px--rem(2px));
    transition: all $speed-faster $ease-in-out-quad;
    position: relative;

    // This is the inner circle on the indicator
    &::after {
      position: absolute;
      opacity: 0;
      color: #1D3148;
      content: '';
      border: solid #1D3148;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      top: 0;
      transition: all $speed-faster $ease-in-out-quad;

      height: px--rem(14px);
      width: px--rem(9px);
      left: px--rem(4px);
    }
  }

  // Hover State (unchecked)
  &:hover {
    color: $text;
  }

  input:checked ~ .ems-checkbox__indicator {
    border-color: #1D3148;
  }

  input:checked ~ .ems-checkbox__indicator::after {
    opacity: 1;
  }
}

.ems-control {
  &.has-icon-right,
  &.has-icon-left {
    position: relative;
  }

  &.has-icon-right {
    .ems-input {
      width: 100%;
      padding-right: ($control-height - $space-xs);
    }
  }

  &.has-icon-left {
    .ems-input {
      width: 100%;
      padding-left: ($control-height - $space-xs);
    }
  }

  &__icon {
    color: $input-placeholder-color;
    height: $control-height;
    width: $control-height - px--rem(1px);
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
    padding: $space-m + $space-xs;
    border-left: px--rem(1px) solid $input-border-color;

    &.is-right {
      right: 0;
    }

    &.is-left {
      left: 0;
    }
  }
}
