/* Icomoon Icons as a fallback for when the SVGs are not an option on web */

// icon font family variable to get around linting issues
$icomoon-font-family: "icomoon";

@font-face {
  font-family: $icomoon-font-family;
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url('./icomoon.woff') format('woff');
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $icomoon-font-family !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Asset-4::before {
  content: "\e92a";
}

.icon-Asset-5 .path1::before {
  content: "\e924";
  color: rgb(255, 255, 255);
}

.icon-Asset-5 .path2::before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-Asset-5 .path3::before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-Asset-6 .path1::before {
  content: "\e923";
  color: rgb(255, 255, 255);
}

.icon-Asset-6 .path2::before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-Asset-7::before {
  content: "\e906";
}

.icon-Asset-8::before {
  content: "\e904";
}

.icon-Asset-9::before {
  content: "\e948";
}

.icon-Asset-10::before {
  content: "\e934";
}

.icon-Asset-11::before {
  content: "\e935";
}

.icon-Asset-12::before {
  content: "\e949";
}

.icon-Asset-13::before {
  content: "\e936";
}

.icon-Asset-14::before {
  content: "\e933";
}

.icon-Asset-15::before {
  content: "\e92d";
}

.icon-Asset-16 .path1::before {
  content: "\e930";
  color: rgb(0, 0, 0);
}

.icon-Asset-16 .path2::before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 81, 81);
}

.icon-Asset-17::before {
  content: "\e92c";
}

.icon-Asset-18::before {
  content: "\e902";
}

.icon-Asset-19::before {
  content: "\e901";
}

.icon-Asset-20::before {
  content: "\e903";
}

.icon-Asset-21::before {
  content: "\e907";
}

.icon-Asset-22::before {
  content: "\e905";
}

.icon-Asset-23::before {
  content: "\e908";
}

.icon-Asset-24::before {
  content: "\e909";
}

.icon-Asset-25::before {
  content: "\e90c";
}

.icon-Asset-26::before {
  content: "\e90d";
}

.icon-Asset-27::before {
  content: "\e90e";
}

.icon-Asset-28::before {
  content: "\e910";
}

.icon-Asset-29::before {
  content: "\e90f";
}

.icon-Asset-30::before {
  content: "\e911";
}

.icon-Asset-31::before {
  content: "\e914";
}

.icon-Asset-32::before {
  content: "\e916";
}

.icon-Asset-33::before {
  content: "\e917";
}

.icon-Asset-34::before {
  content: "\e918";
}

.icon-Asset-35::before {
  content: "\e919";
}

.icon-Asset-36::before {
  content: "\e91e";
}

.icon-Asset-37::before {
  content: "\e920";
}

.icon-Asset-38::before {
  content: "\e921";
}

.icon-Asset-39::before {
  content: "\e91f";
}

.icon-Asset-40::before {
  content: "\e922";
}

.icon-Asset-41::before {
  content: "\e925";
}

.icon-Asset-42::before {
  content: "\e926";
}

.icon-Asset-43::before {
  content: "\e927";
}

.icon-Asset-44::before {
  content: "\e929";
}

.icon-Asset-45::before {
  content: "\e928";
}

.icon-Asset-46::before {
  content: "\e92b";
}

.icon-Asset-52::before {
  content: "\e900";
}

.icon-Asset-53::before {
  content: "\e912";
}

.icon-Asset-54::before {
  content: "\e90b";
}

.icon-Asset-55::before {
  content: "\e90a";
}
