/* Rubik Light – 300 */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: url('./rubik-300.woff2') format('woff2');
}

/* Rubik Regular – 400 */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('./rubik-regular.woff2') format('woff2');
}

/* Rubik Medium – 500 */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('./rubik-500.woff2') format('woff2');
}
