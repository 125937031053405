// Utilities
@import "initial-variables";
@import "functions";
@import "derived-variables";
@import "mixins";
@import "animations";
@import "app-variables";

@include until-tablet {
  @media screen and (orientation: landscape) {
    .cdk-overlay-pane:has(#unsaved-changes-modal), .cdk-overlay-pane:has(#discard-changes-alert) {
      overflow: scroll;
    }
  }
}
