// Color: Primary
$cyan-50: hsl(188, 100%, 98%);
$cyan-100: hsl(188, 100%, 92%);
$cyan-200: hsl(188, 100%, 80%);
$cyan-300: hsl(188, 100%, 60%);
$cyan-400: hsl(188, 100%, 42.8%);   // #00BDDA
$cyan-500: hsl(200, 83%, 43%);      // ~#008EC5
$cyan-600: hsl(200, 100%, 37%);      // ~#007FBE
$cyan-700: hsl(200, 100%, 30%);
$cyan-800: hsl(200, 100%, 25%);
$cyan-900: hsl(200, 100%, 20%);

//check-box border color
$check-box-1: hsl(210, 16%, 87%);
// Color: Accents – Blue
$blue-500: hsl(202.2, 100%, 49.2%); // ~#009EFB
$blue-550: hsl(209, 91%, 54%);      // #1F8BF4
$blue-700: hsl(202.9, 60.1%, 34.3%);

// Color: Accents – Red
$red-300: hsl(359, 55.1%, 55.5%);   // #cc4f51
$red-500: hsl(359, 86%, 51%);       // ~#EC1C24

// Color: Accents – Yellow
$yellow-500: hsl(37.4, 91.4%, 54.9%);   // #f5a623
$yellow-200: hsla(61, 100%, 90%, 1);
$natural-yellow: hsla(47, 100%, 69%, 1); // #FFDC5F

// Color: Accents – Green

$green-300: hsl(126.9, 55.3%, 57.1%);   // ~#55ce63
$green-400: hsl(126.9, 55.3%, 50%);
$green-500: hsl(127.3, 41.4%, 46.9%);   // #46a952

// Color: Black, White and Near White
$white: hsl(0, 0%, 100%);
$black: hsl(0, 0%, 0%);

// Color: Neutrals
$blue-grey-100: hsl(190, 33%, 96%); // ~#f2f7f8
$blue-grey-150: hsl(190, 22%, 92%); // ~#e6e8ef
$blue-grey-200: hsl(190, 14%, 88%);
$blue-grey-300: hsl(214, 7%, 80%); // ~#c8cbcf
$blue-grey-400: #a6b7bf;
$blue-grey-500: hsl(212, 13%, 50%); // ~#6f7f91
$blue-grey-600: #54667a;
$blue-grey-700: #46586c;
$blue-grey-800: hsl(190, 42%, 20%);
$blue-grey-900: hsl(212, 43%, 20%); // ~#1D3148

/**
 * I left these in here because
 * it follows the template and
 * keeps them together instead of moving it to derived_variables.
 */
$primary-dark-100: $blue-grey-900;
$primary-dark-75: #1D3148BF;
$primary-dark-50: #1D314880;
$primary-dark-10: #1D31481A;

$success-50: #94EA9F80;

$select-icon-color: #536779;

$grey-400: hsl(220, 3%, 17%); // ~#2b2c2e

// Typography

$family-sans-serif: 'Rubik', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$render-mode: optimizeLegibility;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-heavy: 600;

$line-height-1: 1.036875rem; // 16.59px
$line-height-2: 1.185rem; // 18.96px

// Spacing
// Sizes based off the 8pt grid
//  xxs     2px     0.125rem
//  xs      4px     0.25rem
//  s       8px     0.5rem
//  m       16px    1rem
//  l       32px    2rem
//  xl      64px    4rem
//  xxl     128px   8rem

$sm-font-size: 0.75rem; // 12px
$md-font-size: 0.875rem; // 14px
$base-font-size: 1rem; // 16px
$large-font-size: 1.125rem; // 18px

$space-xxs: 0.125rem;
$space-xs: 0.25rem;
$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 2rem;
$space-xl: 4rem;
$space-xxl: 8rem;

// Multiply the number by 4 is the size in px.
$gap-1: 0.25rem;            // 4px
$gap-2: calc($gap-1 * 2);   // 8px
$gap-3: calc($gap-1 * 3);   // 12px
$gap-4: calc($gap-1 * 4);   // 16px
$gap-5: calc($gap-1 * 5);   // 20px
$gap-6: calc($gap-1 * 6);   // 24px
$gap-7: calc($gap-1 * 7);   // 28px
$gap-8: calc($gap-1 * 8);   // 32px
$gap-9: calc($gap-1 * 9);   // 36px
$gap-10: calc($gap-1 * 10); // 40px
$gap-05: calc($gap-1 / 2);  // 2px

$spacers: (
  "xxs": $space-xxs,
  "xs": $space-xs,
  "s": $space-s,
  "m": $space-m,
  "l": $space-l,
  "xl": $space-xl,
  "xxl": $space-xxl
);

// Responsive Breakpoints
$mobile: 30rem; // 480px
$tablet:    48rem;  // 768px
$desktop:   60rem;  // 960px
$widescreen: 72rem; // 1152px
$fullhd: 84rem;    // 1344px
$megahd: 90rem; // 1440px
$morehd: 96rem; // 1536px
$ultrahd: 102rem; // 1632px
$uberhd: 110rem; // 1728px

// Radii
$radius-xs: 2px;
$radius-s:  3px;
$radius-m:  5px;
$radius-l:  10px;
$radius-rounded: 290486px;

// Animation
$ease-out: ease-out;
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$speed-fastest: 100ms;
$speed-faster: 150ms;
$speed-fast: 250ms;
$speed: 400ms;
$speed-slow: 600ms;

// Elevation
$box-shadow-01: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
$box-shadow-02: 1px 2px 5px 0 rgba(0, 0, 0, 0.1);
$box-shadow-03: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
$box-shadow-04: 0 2px 25px 0 hsla(0, 0%, 0%, 0.2);
$box-shadow-05: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
$box-shadow-06: 0 1px 3px 0 rgba(0, 0, 0, 0.32);

// Z-Indexes
$z-index-datepicker: 1400;
$z-index-modal: 1200;
$z-index-modal-content: 1100;
$z-index-modal-overlay: 1000;
$z-index-menu: 500;
$z-index-fab: 400;
$z-index-banner: 300;
$z-index-toast: 210;
$z-index-tooltip: 200;
$z-index-top-bar: 20;
$z-index-popover: 40;
$z-index-dropdown: 20;
$z-index-base: 0;
$z-index-neg: -1;
